import { render, staticRenderFns } from "./the-newsletter-sign-up.vue?vue&type=template&id=69420684&scoped=true&"
import script from "./the-newsletter-sign-up.vue?vue&type=script&lang=js&"
export * from "./the-newsletter-sign-up.vue?vue&type=script&lang=js&"
import style0 from "./the-newsletter-sign-up.vue?vue&type=style&index=0&id=69420684&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69420684",
  null
  
)

export default component.exports