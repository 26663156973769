<template>
  <div>
    <form ref="ProfileForm" name="ProfileForm" action="https://media.bakerbookhouse.com/u/register.php" method="get" @submit.prevent="CheckInputs">
      <input type="hidden" name="CID" value="1091360261">
      <input type="hidden" name="SID" :value="SID">
      <input type="hidden" name="UID" :value="UID">
      <input type="hidden" name="f" value="601">
      <input type="hidden" name="p" value="2">
      <input type="hidden" name="a" value="r">
      <input type="hidden" name="el" :value="el">
      <input type="hidden" name="llid" :value="llid">
      <input type="hidden" name="c" :value="c">
      <input type="hidden" name="counted" :value="counted">
      <input type="hidden" name="RID" :value="RID">
      <input type="hidden" name="mailnow" :value="mailnow">
      <input type="hidden" name="inp_4395" value="1">
      <div class="form-elements">
        <div class="form-group">
          <h3>Sign up for our newsletter</h3>
        </div>
      <div class="form-group">
        <label for="inp_1">First Name:</label>
        <input v-model="inp_1" type="text" name="inp_1" maxlength="60">
      </div>
      <div class="form-group">
        <label for="inp_3">Email:</label>
        <input v-model="inp_3" type="text" name="inp_3" maxlength="255">
      </div>
      <div class="optin-group">
        <input v-model="optin" tabindex="882" type="checkbox" name="optin" value="y">
        <label for="optin">I opt-in to receive marketing emails from Baker Book House.</label>
        <input
          aria-label="Submit"
          tabindex="993"
          type="button"
          name="submit1"
          value="Submit"
          @click="SubmitIt"
          >
      </div>
    </div>
</form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      SID: '', // Add your data properties here
      UID: '',
      el: '',
      llid: '',
      c: '',
      counted: '',
      RID: '',
      mailnow: '',
      inp_1: '',
      inp_3: '',
      inp_4395: '',
      optin: 'true',
      error: ''
    }
  },
  methods: {
    CheckInputs () {
      let checkOk = true
      this.error = "Wrong input!\n"

      // checkOk = this.is_0_valid() && checkOk
      checkOk = this.is_1_valid(this.inp_1) && checkOk
      checkOk = this.is_3_valid(this.inp_3) && checkOk

      if (!checkOk) {
        alert('***' + this.error)
      }
      return checkOk
    },
    is_0_valid () {
      let count = 0
      if (this.optin) {
        if (this.optin === 'True') {
          if (this.$refs.ProfileForm['interest[]']) {
            const Interest = this.$refs.ProfileForm['interest[]']
            for (let i = 0; i < Interest.length; i++) {
              if (Interest[i].checked) { ++count }
            }
            if (count === 0) {
              this.error += "Interests:"
              return false
            }
          }
        }
      }
      return true
    },
    is_1_valid (input) {
      if (input === "") {
        this.error += "First Name: missing data!\n"
        return false
      }
      return true
    },
    is_3_valid (input) {
      if (input === "") {
        this.error += "Email: missing data!\n"
        return false
      }
      return true
    },
    SubmitIt () {
      if (this.CheckInputs()) {
        if (this.onbeforesubmit) { this.onbeforesubmit() }
        this.$refs.ProfileForm.submit()
      }
    },
    onbeforesubmit () {
      return true
    },
    MailIt () {
      if (this.CheckInputs()) {
        if (this.$refs.ProfileForm.subject.value === '' || this.$refs.ProfileForm.msg.value === '') {
          alert('Please enter a subject and message before sending your email.')
        } else {
          this.$refs.ProfileForm.submit()
        }
      }
    },
    FieldWithName (frm, fieldname, numofield = 0) {
      let fieldCount = 0
      for (let i = 0; i < frm.elements.length; ++i) {
        if (frm.elements[i].name === fieldname) {
          if (fieldCount === numofield) { return frm.elements[i] } else { fieldCount++ }
        }
      }
    },
    NumChecked (frm, fieldname) {
      let count = 0
      for (let i = 0; i < frm.elements.length; ++i) {
        if (frm.elements[i].name === fieldname && frm.elements[i].checked === true) { ++count }
      }
      return count
    },
    NumSel (field) {
      let count = 0
      for (let i = 0; i < field.length; ++i) {
        if (field[i].selected === true) { ++count }
      }
      return count
    }
  }
}
</script>
<style scoped>
/* Add your CSS overrides here */
.form-elements {
  width:50%;
  max-width:500px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  font-size:1.4em;
  font-weight:bold;
  margin-bottom:20px;
}
.form-group {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top:10px;
}
.form-group h3 {
  margin:0 auto;
}
.form-group label {
  width: 150px;
  margin-right: 10px;
  text-align: right;
}
.optin-group {
  display: flex;
  align-items: center;
  margin-top:20px;
  justify-content:flex-end;
}
.optin-group label {
  margin-right: 10px;
  font-size:.9em;
}
input[type="text"] {
  border: 1px solid #ccc;
  padding: 10px;
  flex: 1;
}

input[type="checkbox"] {
  margin-right: 5px;
}

input[type="button"] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

input[type="button"]:hover {
  background-color: #45a049;
}

/* Mobile CSS */
@media (max-width: 600px) {
  .form-elements {
    width: 90%;
  }
  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .form-group label {
    width: auto;
    margin-right: 0;
    margin-bottom: 5px;
    text-align: left;
  }
  .form-group input[type="text"] {
    width: 100%;
  }
  .optin-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .optin-group input[type="checkbox"],
  .optin-group label {
    display: inline-block;
    margin-right: 5px;
  }
  .optin-group input[type="button"] {
    margin-top: 10px;
    width: 100%;
    display:block;
  }
}
</style>
