import * as Msal from 'msal'
import { captureException } from 'logrocket'
import {
  storeItem,
  getItem,
  clearItem
} from './local-storage'

const ScopeUri = `https://bakerbookhouse.onmicrosoft.com/${process.env.B2C_ENV}-bbh-ecomm-api/`
const Scopes = [
  'user_impersonate'
]
const B2C_SCOPES = Scopes.map(s => ScopeUri + s)

const lastUsedAuthority = 'last_used_authority'

let msalApp
let tokenAccess

const initUserAgent = (authority) => {
  storeItem(lastUsedAuthority, authority)
  const userAgentApplication = new Msal.UserAgentApplication({
    auth: {
      clientId: process.env.B2C_CLIENT_ID,
      authority: authority || process.env.B2C_AUTHORITY,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: process.env.APP_URL,
      validateAuthority: false,
      redirectUri: process.env.APP_URL + '/auth/callback'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    },
    system: {
      loadFrameTimeout: 90000
    }
  })
  userAgentApplication.handleRedirectCallback(acquireTokenRedirectCallBack)
  return userAgentApplication
}

export const azureADSignIn = (authority = null) => {
  const value = authority || process.env.B2C_AUTHORITY
  msalApp = initUserAgent(value)
  msalApp.loginRedirect({ scopes: B2C_SCOPES })
}

export const acquireTokenSilent = async () => {
  const authority = getItem(lastUsedAuthority)
  if (!msalApp) {
    msalApp = initUserAgent(authority)
    clearItem(lastUsedAuthority)
  }
  const request = { scopes: B2C_SCOPES }

  return await msalApp.acquireTokenSilent(request).then((response) => {
    return response
  })
    .catch((error) => {
      console.log('attempting to access token')
      console.log(error)
      msalApp.acquireTokenRedirect(request)
      return tokenAccess
    })
}

export const azureADSignOut = () => {
  if (!msalApp) {
    msalApp = initUserAgent()
  }
  msalApp.logout()
}

export const acquireTokenRedirectCallBack = (error, response) => {
  console.log('attempting to access token - acquireTokenRedirect')
  console.log(error)
  if (error && error.errorMessage.includes('AADB2C90118')) {
    storeItem('force_auth_on_callback', true)
    const forgotPassAuthority = process.env.B2C_FORGOT_PASS_URL
    msalApp = initUserAgent(forgotPassAuthority)
    msalApp.loginRedirect()
  } else if (!error) {
    tokenAccess = response
    console.log('*********** logged in ***********')
    const email = response.idTokenClaims?.emails ? response.idTokenClaims.emails[0] : null
    if (email) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'userLoggedIn',
        userEmail: email
      })
    }
  } else if (error) {
    throw captureException
  }
}
